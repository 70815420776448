const englishContent = require('./stories-english');
const italianContent = require('./stories-italian');
const indonesianContent = require('./stories-indonesian');
const languageContent = require('./languageContent');

const content = {
  en: englishContent,
  it: italianContent,
  in: indonesianContent
};

export const data = {
  all: (language) => {
    return content[language];
  },
  storyByID: (language, id) => {
    return content[language].find(story => story.id === id);
  },
  eventByID: (language, id) => {
    return data.combinedEvents(language).find(event => event.id === id);
  },
  translationsByLanguage: (language) => {
    return languageContent.find(c => c.language === language);
  },
  combinedEvents: (language) => {
    // return english.reduce((previousValue, currentValue) => previousValue.concat([...currentValue.events]), []);
    let result = [];
    content[language].forEach((story) => {
      result = result.concat(story.events)
    });
    return result;
  },
  allContentByStory: (story) => {
    const getExt = (string) => {
      const duplicate = string;
      return duplicate.split('.').pop();
    }

    let result = [];
    story.events.forEach((event) => {
      result = result.concat(event.content)
    });
    // result.sort((a, b) => {
    //   return (getExt(a) < getExt(b) ? 1 : -1);
    // });
    story.events.forEach((event) => {
      result.unshift(event.avatar)
    });
    return result;
  }
}
