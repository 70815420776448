<template>
    <svg width="221" height="76" viewBox="0 0 221 76" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M182.906 3L215.997 38.4544L182.906 73.9087" stroke="#006DAE" stroke-width="6"/>
    <path d="M216 38H0" stroke="#006DAE" stroke-width="6"/>
    </svg>
    
    
    </template>
    
    <style lang="scss" scoped>
    @import "../../styles/main.scss";
    
    svg {
        position: absolute;
        right: 0;
        bottom: 0;
        display: block;
        width: 40px;
        height: 10px;
        transform: rotate(-90deg);
    }
    @include media(">=tablet", "height>mediumHeight" ) {
        svg {
            width: 50px;
            height: 14px;
        }
    }
    @include media(">=portraitHD", "portrait") {
        svg {
            width: 109px;
            height: 26px;
        }
    }
    @include media(">=portrait2k", "portrait") {
        svg {
            width: 145px;
            height: 34px;
        }
    }
    
    @include media(">=portrait4k", "portrait") {
        svg {
            width: 216px;
            height: 50px;
        }
    }
    </style>