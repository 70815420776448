<template>
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M59.9084 61.9993L2 4.09093L4.09093 2L61.9993 59.9084L59.9084 61.9993Z" fill="#006DAE" stroke="#006DAE"
      stroke-width="2.45833" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.0007 59.9084L59.9091 2L62 4.09093L4.09163 61.9993L2.0007 59.9084Z"
      fill="#006DAE" stroke="#006DAE" stroke-width="2.45833" />
  </svg>
</template>

<style lang="scss" scoped>
@import "../../styles/main.scss";

svg {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 20px;
  height: 20px;
}
@include media(">=portraitHD", "portrait") {
  svg {
    width: 79px;
    height: 79px;
  }
}
@include media(">=portrait2k", "portrait") {
  svg {
    width: 105px;
    height: 105px;
  }
}

@include media(">=portrait4k", "portrait") {
  svg {
    width: 125px;
    height: 125px;
  }
}
</style>