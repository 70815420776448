<template>
<svg width="105" height="180" viewBox="0 0 135 180" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="48" cy="25" r="23" stroke="#006DAE" stroke-width="4" stroke-dasharray="8 8"/>
<circle cx="48" cy="25" r="14" fill="#006DAE" fill-opacity="0.4"/>
<path d="M90.2793 86.7436V76.8207C90.2793 74.4959 90.9489 72.4168 92.2881 70.9047C93.6272 69.4116 95.6552 68.4666 98.3527 68.4666C103.748 68.4666 107 72.1711 107 76.8207V123.014C107 127.191 106.292 131.066 105.374 134.374C102.791 143.616 101.375 149.967 100.993 158.434C100.84 161.553 100.974 164.709 101.337 167.828V167.904C101.662 170.606 100.228 173.29 97.53 174.727C93.1681 177.108 85.0947 180 71.6837 180C57.5074 180 49.8167 176.466 46.0669 173.819C44.0964 172.44 42.9485 170.38 42.9485 168.187V168.149C42.6233 164.086 42.3172 162.214 41.705 160.192C39.524 153.01 40.2319 152.877 33.842 144.221C28.9636 137.624 27.0313 123.165 23.3007 117.117L4.26511 95.0599C1.43369 91.6767 0.553647 86.9893 5.81474 82.6988C10.6932 78.7108 15.3804 81.6782 17.6187 83.5305L39.1988 103.906L39.1988 36.4108C39.1988 34.086 40.1554 31.9881 41.6859 30.4571C43.2355 28.945 45.3591 28 47.7122 28C52.3228 28 56.0534 31.61 56.2065 36.1273V86.7625V65.8771C56.2065 61.2276 60.0136 57.4663 64.7008 57.4663C69.4071 57.4663 73.2142 61.2276 73.2142 65.8771V86.7625V71.1316C73.2142 66.482 77.0213 62.7207 81.7276 62.7207C84.0807 62.7207 86.2043 63.6658 87.754 65.1778C89.2844 66.7088 90.241 68.8068 90.241 71.1316V86.7625L90.2793 86.7436Z" fill="#E1E3E7" stroke="#006DAE" stroke-width="4" stroke-miterlimit="10"/>
</svg>

</template>
<style lang="scss" scoped>
@import "../../styles/main.scss";

svg {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 32px;
    height: 55px;
}
@include media(">=tablet", "height>mediumHeight" ) {
  svg {
    width: 32px;
    height: 55px;
  }
}

@include media(">=portraitHD", "portrait") {
  svg {
    width: 64px;
    height: 109px;
  }
}

@include media(">=portrait2k", "portrait") {
  svg {
    width: 85px;
    height: 145px;
  }
}

@include media(">=portrait4k", "portrait") {
  svg {
    width: 126px;
    height: 216px;
  }
}</style>