import { defineStore } from 'pinia';
import { ref, computed, watch } from 'vue';
import { data } from "../data/dataHelper";
import { useRouter, useRoute } from 'vue-router'

export const enum Languages {
  EN = 'en',
  IT = 'it',
  IN = 'in'
}

export const enum PageTransitions {
  PAGE = 'page',
  SLIDE_UP = 'slide-up',
  SLIDE_DOWN = 'slide-down',
  NONE = 'none'
}

enum LegendState {
  HOME = 0,
  INTRO = 1,
  TIMELINE = 2,
  GALLERY = 3
}

type BabyEvent = {
  "id": string,
  "age": number,
  "year": number,
  "title": string,
  "description": string,
  "avatar": string,
  "content": string[]
}

export const enum GridSize {
  SMALL = 1,
  MEDIUM = 2,
  LARGE = 3,
  XLARGE = 4
}

export const enum TimelineTransition {
  NEXT_TRANSITION = "timeline-content-next",
  PREV_TRANSITION = "timeline-content-prev",
}

const useStore = defineStore('main', () => {
  const router = useRouter()
  const route = useRoute()

  /**
   * State
   */
  const isElectron = ref(navigator.userAgent.toLowerCase().indexOf(' electron/') > -1)

  const language = ref(Languages.EN)
  const pageTransitionName = ref('page')

  const lastSelectedBaby = ref('olivia')
  const legendState = ref(LegendState.INTRO)
  const selectedIndex = ref(0)
  const timelineID = ref('olivia')
  const showScrollContent = ref(false)
  const contentIsScrollable = ref(false)
  const showExploreTimeline = ref(false)
  const showIntroBaby = ref(false)
  const timelineButtonWidths = ref([""])
  const showGallery = ref(false)

  const showAvatar = ref(false)
  const showImage = ref(false)
  const gridSize = ref(GridSize.SMALL)
  const isSwiping = ref(false)
  const disableTopSwipe = ref(false)
  const openArticle = ref(false);
  const showLegend = ref(true)
  let legendTimeout;
  const scrollTop = ref(0)
  const showArch = ref(false)

  const timelineTransition = ref(TimelineTransition.NEXT_TRANSITION)

  /** 
   * Setters
   */

  const setLanguage = (lang: Languages) => {
    language.value = lang
  }

  const setPageTransitionName = (value: PageTransitions) => {
    pageTransitionName.value = value
  }


  /**
   * Global Functions
   */

  const getTimelineLength = () => {
    if (!timelineID.value) return {};
    return data.storyByID(
      language.value,
      timelineID.value
    ).events.length + 1;
  }

  const selectAgeFromIndex = (index: number) => {
    selectedIndex.value = index;
    if (index !== 0) {
      showImage.value = false;
      setTimeout(() => {
        showScrollContent.value = true;
        showAvatar.value = true;
      }, 500);
    } else {
      // showImage.value = true;
      showScrollContent.value = false;
      showAvatar.value = false;
    }

    updateTimelineButtons();
  }

  const updateTimelineButtons = () => {
    if (showGallery.value) {
      if (selectedIndex.value === 1) {
        timelineButtonWidths.value[0] = "0%";
        timelineButtonWidths.value[1] = "50%";
        timelineButtonWidths.value[2] = "10%";
        timelineButtonWidths.value[3] = "45%";
        timelineButtonWidths.value[4] = "0%";
      } else {
        timelineButtonWidths.value[0] = "0%";
        timelineButtonWidths.value[1] = "12.5%";
        timelineButtonWidths.value[2] = "25%";
        timelineButtonWidths.value[3] = "25%";
        timelineButtonWidths.value[4] = "25%";
        timelineButtonWidths.value[5] = "12.5%";
        timelineButtonWidths.value[6] = "0%";
      }
    } else {
      const l = getTimelineLength();

      const leftWidth = (1 / selectedIndex.value) * 0.9 * 0.5;
      const rightWidth = (1 / (l - selectedIndex.value)) * 0.9 * 0.5;
      if (selectedIndex.value <= 0) {
        timelineButtonWidths.value[0] = "45%";
      } else {
        timelineButtonWidths.value[0] = "0%";
      }
      if (selectedIndex.value >= l) {
        timelineButtonWidths.value[l + 2] = "45%";
      } else {
        timelineButtonWidths.value[l + 2] = "0%";
      }
      for (let i = 1; i < l + 2; i++) {
        if (i < selectedIndex.value + 1) {
          timelineButtonWidths.value[i] = leftWidth * 100 + "%";
        } else if (i > selectedIndex.value + 1) {
          timelineButtonWidths.value[i] = rightWidth * 100 + "%";
        } else {
          timelineButtonWidths.value[i] = "10%";
        }
      }
    }
  }

  const story = computed((): any => {
    if (!timelineID.value) return {};
    return data.storyByID(
      language.value,
      timelineID.value
    );
  })

  const selectedEvent = computed((): any => {
    if (selectedIndex.value === 0) return {};
    return story.value.events[selectedIndex.value - 1]
  })



  /**
   * Navigation
   *  */

  // const goToHome = () => {
  //   router.push('/')
  //   legendState.value = 0
  //   showExploreTimeline.value = false
  //   pageTransitionName.value = PageTransitions.SLIDE_DOWN
  // }

  const goToIntro = () => {
    if (legendState.value === 0) {
      pageTransitionName.value = PageTransitions.SLIDE_UP
    } else if (legendState.value === 2 || legendState.value === 3) {
      pageTransitionName.value = PageTransitions.SLIDE_DOWN
    }
    router.push('/')
    legendState.value = 0
    showExploreTimeline.value = false

  }

  const goToMenu = () => {
    if (legendState.value === 0) {
      pageTransitionName.value = PageTransitions.SLIDE_UP
      // if ( selectedIndex.value !== 0)
      resetTimeline();
    } else if (legendState.value === 2) {
      pageTransitionName.value = PageTransitions.SLIDE_DOWN
    }
    router.push('/explore')
    legendState.value = 1
    showExploreTimeline.value = true

  }

  const goToTimeline = () => {
    pageTransitionName.value = PageTransitions.SLIDE_UP
    router.push({ path: '/explore', query: { id: lastSelectedBaby.value } })
    legendState.value = 2
    selectedIndex.value = 0;
    timelineID.value = lastSelectedBaby.value
    updateTimelineButtons()
    showExploreTimeline.value = false

  }
  const resetTimeline = () => {
    selectedIndex.value = 0
    lastSelectedBaby.value = 'olivia'
    timelineID.value = 'olivia'
  }
  const resetExperience = () => {
    // goToHome()
    resetTimeline()
  }

  watch(() => route.query.id, (id) => {
    if (route.fullPath === '/intro' || route.fullPath === '/') return
    if (id === undefined) {
      goToMenu()
    } else {
      goToTimeline()
    }
  })

  return {
    isElectron,

    language,
    showGallery,
    pageTransitionName,
    lastSelectedBaby,
    legendState,
    selectedIndex,
    timelineID,
    timelineButtonWidths,
    showAvatar,
    showImage,
    showScrollContent,
    gridSize,
    isSwiping,
    disableTopSwipe,
    contentIsScrollable,
    showExploreTimeline,
    showIntroBaby,
    openArticle,
    showLegend,
    legendTimeout,
    scrollTop,
    timelineTransition,
    showArch,

    setLanguage,
    setPageTransitionName,

    // global
    updateTimelineButtons,
    selectAgeFromIndex,
    story,
    selectedEvent,
    getTimelineLength,

    // Navigation
    // goToHome,
    goToIntro,
    goToTimeline,
    goToMenu,
    resetExperience,
    resetTimeline,
  };
});

export default useStore;
