<template>
    <svg width="780" height="1134" viewBox="0 0 780 1134" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="779.638" cy="1133.64" r="779.638" fill="#006DAE" />
        <circle cx="779.632" cy="1133.64" r="515.745" stroke="#6282C2" stroke-opacity="0.4" stroke-width="6.55572" />
        <circle cx="779.632" cy="1133.64" r="515.745" stroke="black" stroke-opacity="0.4" stroke-width="6.55572" />
        <circle cx="779.643" cy="1133.64" r="246.295" stroke="white" stroke-opacity="0.5" stroke-width="6.55572" />
        <circle cx="779.636" cy="1133.64" r="86.1357" fill="white" fill-opacity="0.2" />
        <circle cx="559.947" cy="19.9467" r="19.9467" fill="black" />
        <circle cx="559.947" cy="119.679" r="19.9467" fill="black" />
        <circle cx="559.947" cy="219.413" r="19.9467" fill="black" />
        <circle cx="559.947" cy="319.148" r="19.9467" fill="black" />
        <circle cx="559.947" cy="418.881" r="19.9467" fill="black" />
        <circle cx="659.681" cy="219.413" r="19.9467" fill="black" />
        <circle cx="659.681" cy="319.148" r="19.9467" fill="black" />
        <circle cx="659.681" cy="418.881" r="19.9467" fill="black" />
        <circle cx="659.681" cy="518.614" r="19.9467" fill="black" />
    </svg>
</template>

<style lang="scss" scoped>
@import "../../styles/main.scss";

svg {
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    width: 100px;
    height: 150px;
}

@include media(">=tablet") {
    svg {
        width: 156px;
        height: 228px;
    }
}

@include media(">=portraitHD", "portrait") {
    svg {
        width: 392px;
        height: 570px;
    }
}

@include media(">=portrait2k", "portrait") {
    svg {
        width: 522px;
        height: 760px;
    }
}

@include media(">=portrait4k", "portrait") {
    svg {
        width: 780px;
        height: 1134px;
    }
}</style>