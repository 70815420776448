<template>
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50" cy="50" r="47" fill="white" fill-opacity="0.1" stroke="#006DAE" stroke-width="6" />
    <path d="M54.5425 35.3801H45.3933V27.8416H54.5425V35.3801ZM45.3933 40.5346H54.5425V73.8457H45.3933V40.5346Z"
      fill="#006DAE" />
  </svg>
</template>
<style lang="scss" scoped>
@import "../../styles/main.scss";

svg {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

@include media(">=portraitHD", "portrait") {
  svg {
    width: 50px;
    height: 50px;
  }
}

@include media(">=portrait2k", "portrait") {
  svg {
    width: 67px;
    height: 67px;
  }
}

@include media(">=portrait4k", "portrait") {
  svg {
    width: 100px;
    height: 100px;
  }
}
</style>