<template>
<svg width="135" height="180" viewBox="0 0 135 180" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="67" cy="25" r="23" stroke="#006DAE" stroke-width="4"/>
<circle cx="67" cy="25" r="14" fill="#006DAE" fill-opacity="0.4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M117 10.6685L119.892 8L135 24L119.892 40L117 37.3315L129.588 24L117 10.6685Z" fill="#006DAE"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M90 21.999L133 21.999L133 25.999L90 25.999L90 21.999Z" fill="#006DAE"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18 37.3315L15.1076 40L1.39876e-06 24L15.1076 8L18 10.6685L5.41205 24L18 37.3315Z" fill="#006DAE"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M45 26.001L2 26.001L2 22.001L45 22.001L45 26.001Z" fill="#006DAE"/>
<path d="M109.279 86.7436V76.8207C109.279 74.4959 109.949 72.4168 111.288 70.9047C112.627 69.4116 114.655 68.4666 117.353 68.4666C122.748 68.4666 126 72.1711 126 76.8207V123.014C126 127.191 125.292 131.066 124.374 134.374C121.791 143.616 120.375 149.967 119.993 158.434C119.84 161.553 119.974 164.709 120.337 167.828V167.904C120.662 170.606 119.228 173.29 116.53 174.727C112.168 177.108 104.095 180 90.6837 180C76.5074 180 68.8167 176.466 65.0669 173.819C63.0964 172.44 61.9485 170.38 61.9485 168.187V168.149C61.6233 164.086 61.3172 162.214 60.705 160.192C58.524 153.01 59.2319 152.877 52.842 144.221C47.9636 137.624 46.0313 123.165 42.3007 117.117L23.2651 95.0599C20.4337 91.6767 19.5536 86.9893 24.8147 82.6988C29.6932 78.7108 34.3804 81.6782 36.6187 83.5305L58.1988 103.906L58.1988 36.4108C58.1988 34.086 59.1554 31.9881 60.6859 30.4571C62.2355 28.945 64.3591 28 66.7122 28C71.3228 28 75.0534 31.61 75.2065 36.1273V86.7625V65.8771C75.2065 61.2276 79.0136 57.4663 83.7008 57.4663C88.4071 57.4663 92.2142 61.2276 92.2142 65.8771V86.7625V71.1316C92.2142 66.482 96.0213 62.7207 100.728 62.7207C103.081 62.7207 105.204 63.6658 106.754 65.1778C108.284 66.7088 109.241 68.8068 109.241 71.1316V86.7625L109.279 86.7436Z" fill="#E1E3E7" stroke="#006DAE" stroke-width="4" stroke-miterlimit="10"/>
</svg>
</template>
<style lang="scss" scoped>
@import "../../styles/main.scss";

svg {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 32px;
  height: 55px;
}
@include media(">=tablet", "height>mediumHeight" ) {
  svg {
    width: 32px;
    height: 55px;
  }
}
@include media(">=portraitHD", "portrait") {
  svg {
    width: 64px;
    height: 109px;
  }
}

@include media(">=portrait2k", "portrait") {
  svg {
    width: 109px;
    height: 145px;
  }
}

@include media(">=portrait4k", "portrait") {
  svg {
    width: 162px;
    height: 216px;
  }
}</style>