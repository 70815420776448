import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import LandingView from '../views/LandingView.vue'
import IntroView from '../views/IntroView.vue'

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/',
  //   name: 'landing',
  //   component: LandingView
  // },
  {
    path: '/',
    name: 'intro',
    component: IntroView
    // component: () => import(/* webpackChunkName: "about" */ '../views/IntroView.vue')
  },

  {
    path: '/explore',
    name: 'explore',
    component: () =>
      import(/* webpackChunkName: "permissions" */ '../views/TimelineView.vue'),
    props: route => ({ query: route.query.id })
  },
]

const router = createRouter({
  // when building electron app, change history to createWebHashHistory()
  history: createWebHistory(),
  routes
})

export default router
